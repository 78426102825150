.site-footer {
    background-color: #f1f1f1;
    padding: 4rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    border-top: 1px solid #eee;
}

.footer-section h4 {
    font-size: 1rem;
    color: #333;
    margin: 0 0 1rem 0;
    font-weight: 500;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section li {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    color: #666;
    cursor: pointer;
}

.footer-section li:hover {
    color: #333;
}
