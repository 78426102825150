.content-display {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.content-header {
  margin-bottom: 3rem;
  text-align: center;
}

.content-header h1 {
  font-size: 2.5rem;
  font-weight: 300;
  color: #2c2c2c;
  margin-bottom: 1rem;
  letter-spacing: 1.5px;
}

.content-meta {
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: #666;
  font-size: 0.9rem;
}

.content-date::before {
  content: '📅 ';
}

.content-category::before {
  content: '📑 ';
}

.content-body {
  line-height: 1.8;
  color: #333;
  font-size: 1.1rem;
  background-color: #fff;
  padding: 0rem 4rem 2rem 4rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 2rem 2rem 2rem;
}

/* Paragraph styling */
.content-body p {
  margin-bottom: 1.5rem;
  text-align: justify;
}

/* Image styling */
.content-image {
  margin: 0rem -4rem 2rem -4rem;
  text-align: center;
  background-color: #f8f8f8;
  padding: 2rem 4rem;
}

.content-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-image figcaption {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
}

/* Quote styling */
.content-quote {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f8f8;
  border-left: 4px solid #2c2c2c;
  font-style: italic;
  color: #444;
}

.content-quote p {
  margin: 0;
}

/* List styling */
.content-body ul,
.content-body ol {
  margin: 1.5rem 0;
  padding-left: 2rem;
}

.content-body li {
  margin-bottom: 0.5rem;
}

/* Heading styling */
.content-body h2,
.content-body h3,
.content-body h4 {
  margin: 2rem 0 1rem;
  color: #2c2c2c;
  font-weight: 400;
}

.content-body h2 {
  font-size: 1.8rem;
}

.content-body h3 {
  font-size: 1.5rem;
}

.content-body h4 {
  font-size: 1.2rem;
}

/* Link styling */
.content-body a {
  color: #2c2c2c;
  text-decoration: none;
  border-bottom: 1px solid #2c2c2c;
  transition: all 0.2s ease;
}

.content-body a:hover {
  color: #666;
  border-bottom-color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-display {
    padding: 1rem;
  }

  .content-header h1 {
    font-size: 2rem;
  }

  .content-body {
    padding: 0 2rem 2rem 2rem;
    margin: 0 1rem;
    font-size: 1rem;
  }

  .content-image {
    margin: 0rem -2rem 2rem -2rem;
    padding: 1rem 2rem;
  }
} 