.story-card {
    display: flex;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-decoration: none;
    gap: 1.5rem;
}

.story-card:hover {
    transform: translateX(4px);
}

.story-image {
    flex: 0 0 20%;
    min-width: 150px;
}

.story-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.story-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.story-content h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
    color: #333;
}

.story-content p {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .story-card {
        flex-direction: column;
    }

    .story-image {
        flex: 0 0 200px;
        min-width: auto;
        width: 100%;
    }

    .story-image img {
        height: 200px;
    }
}
