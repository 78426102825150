.stories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem 0;
}

.stories-grid .story-card {
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.stories-grid .story-image {
  flex: 0 0 200px;
  min-width: 100%;
  width: 100%;
}

.stories-grid .story-image img {
  width: 100%;
  height: 200px;
  border-radius: 8px 8px 0 0;
}

.stories-grid .story-content {
  padding: 1.5rem;
}

.stories-grid .story-card {
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stories-grid .story-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .stories-grid {
    grid-template-columns: 1fr;
  }

  .stories-grid .story-card {
    margin: 0;
  }
} 