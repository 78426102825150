.page-title {
    font-size: 2.5rem;
    font-weight: 300;
    color: #2c2c2c;
    margin: 2rem 0;
    letter-spacing: 2px;
    position: relative;
    padding-bottom: 1rem;
    animation: fadeIn 0.8s ease-out forwards;
}

.page-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 1px;
    background-color: #2c2c2c;
}

.section-title {
    font-size: 1.75rem;
    font-weight: 300;
    color: #2c2c2c;
    margin: 2rem 0;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    gap: 1rem;
    animation: fadeIn 0.8s ease-out forwards;
    animation-delay: 0.2s;
    opacity: 0;
}

.section-title::before,
.section-title::after {
    content: '';
    height: 1px;
    background-color: #e0e0e0;
}

.section-title::before {
    width: 24px;
}

.section-title::after {
    flex-grow: 1;
}

.subsection-title {
    font-size: 1.25rem;
    font-weight: 400;
    color: #4a4a4a;
    margin: 1.5rem 0;
    letter-spacing: 1px;
    position: relative;
    padding-left: 1rem;
}

.subsection-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 1em;
    background-color: #e0e0e0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.stories-section .section-title {
    animation-delay: 0.4s;
}