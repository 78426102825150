.App {
  min-height: 100vh;
  background-color: #f1f1f1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.poetry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.stories-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0;
}

h2, h3 {
  color: #333;
  margin-bottom: 20px;
}

.poetry-section, .stories-section {
  margin: 3rem 0;
}

.poetry-section h2 {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 2rem;
  font-weight: 500;
}

.poetry-section h3, .stories-section h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .site-footer {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .poetry-grid {
    grid-template-columns: 1fr;
  }

  .story-card {
    flex-direction: column;
  }

  .story-image {
    flex: 0 0 auto;
  }

  .story-image img {
    width: 100%;
    height: 200px;
  }
}

.more-link {
  display: inline-block;
  color: #666;
  font-size: 0.9rem;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.poetry-section .more-link {
  margin-top: 2rem;
}

.more-link:hover {
  color: #333;
}

.more-link::after {
  content: ' →';
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.more-link:hover::after {
  opacity: 1;
}
