.poetry-card {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: block;
    text-decoration: none;
    height: 100%;
    padding: 1rem;
    margin: 3rem 0;
}

.poetry-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #f5f5f5;
}

.card-title {
    font-size: 1.1rem;
    margin: 1rem;
    color: #333;
}

.card-description {
    font-size: 0.9rem;
    color: #666;
    margin: 0 1rem 1rem;
    line-height: 1.5;
}
