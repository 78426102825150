.site-header {
    padding: 2.5rem 4rem;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
}

.site-header h1 {
    font-size: 1.75rem;
    font-weight: 300;
    color: #2c2c2c;
    margin: 0;
    letter-spacing: 1.5px;
    position: relative;
    display: inline-block;
}

.site-header h1::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 40px;
    height: 1px;
    background-color: #2c2c2c;
    transition: width 0.3s ease;
}

.site-header h1:hover::after {
    width: 100%;
}
